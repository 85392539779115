import React, { Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ContentLoader from "react-content-loader";
import { connect } from 'react-redux';
class AddComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
      
        var settings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesPerRow: 3,
            slidesToScroll: 1
        };
        return ( <>
            <div className="panel">
                <div className="panel-body">
                    <Slider {...settings}>
                        <div>
                            <div className="panel-item text-center" onClick={()=>this.props.addComponent(0,{     title:"What is Lorem Ipsum?",
                            content:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae, repellendus, earum odit quasi laboriosam qui repellat ipsa harum eligendi facilis rerum? Alias inventore possimus nihil tempora! Dignissimos eaque voluptate necessitatibus autem inventore explicabo. Vero, error ad voluptas corporis reiciendis molestias sequi quo est cum animi recusandae porro nobis suscipit velit!",
                            background:null,})}>
                            <div className="panel-head">
                                Intro Section
                            </div>
                           {<ContentLoader animate={false} backgroundColor="#415889" foregroundColor="#415889">
                                <rect x="0" y="10" rx="5" ry="5" width="280" height="50" />
                                <rect x="40" y="80" rx="4" ry="4" width="200" height="13" />
                                <rect x="50" y="100" rx="3" ry="3" width="180" height="10" />
                                <rect x="110" y="130" rx="3" ry="3" width="50" height="30" />
                            </ContentLoader>}
                        </div>
                        </div>
                       
                        <div>
                            <div className="panel-item text-center" onClick={()=>this.props.addComponent(1,{     title:"Our Services",
                            content:[
                                {title:"Service Title",content:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid minima ullam praesentium nostrum, illum dolore.",icon:null},
                                {title:"Service Title",content:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid minima ullam praesentium nostrum, illum dolore.",icon:null},
                                {title:"Service Title",content:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid minima ullam praesentium nostrum, illum dolore.",icon:null},
                                {title:"Service Title",content:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid minima ullam praesentium nostrum, illum dolore.",icon:null},
                            ],
                            background:null,})}>
                            <div className="panel-head">
                                Services Section
                            </div>
                           {<ContentLoader animate={false} backgroundColor="#415889" foregroundColor="#415889">
                                <rect x="0" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="13" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="17" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="23" y="130" rx="3" ry="3" width="30" height="30" />
                                <rect x="90" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="103" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="107" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="113" y="130" rx="3" ry="3" width="30" height="30" />
                                <rect x="180" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="195" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="200" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="205" y="130" rx="3" ry="3" width="30" height="30" />
                            </ContentLoader>}
                           
                        </div>
                        </div>
                        <div>
                            <div className="panel-item text-center" onClick={()=>this.props.addComponent(2,{
                                title:"Our Team",
                                content:[
                                    {name:"Gamal",content:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid minima ullam praesentium nostrum, illum dolore.",
                                    title:"Front End Developer",
                                    facebook:"#",
                                    twitter:"#",
                                    linkedin:"#",
                                    youtube:"#",

                                    },
                                    {name:"Moataz",content:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid minima ullam praesentium nostrum, illum dolore.",
                                    title:"Front End Developer",
                                    facebook:"#",
                                    twitter:"#",
                                    linkedin:"#",
                                    youtube:"#",

                                    },
                                    {name:"Ebrahem",content:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid minima ullam praesentium nostrum, illum dolore.",
                                    title:"Front End Developer",
                                    facebook:"#",
                                    twitter:"#",
                                    linkedin:"#",
                                    youtube:"#",

                                    },
                                    {name:"Saeed Gamal",content:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid minima ullam praesentium nostrum, illum dolore.",
                                    title:"Front End Developer",
                                    facebook:"#",
                                    twitter:"#",
                                    linkedin:"#",
                                    youtube:"#",

                                    },
                                    {name:"Ahmed Atef",content:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid minima ullam praesentium nostrum, illum dolore.",
                                    title:"Front End Developer",
                                    facebook:"#",
                                    twitter:"#",
                                    linkedin:"#",
                                    youtube:"#",

                                    },
                                ]
                            })}>
                            <div className="panel-head">
                                Team Section
                            </div>
                           {<ContentLoader animate={false} backgroundColor="#415889" foregroundColor="#415889">
                                <rect x="0" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="13" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="17" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="23" y="130" rx="3" ry="3" width="30" height="30" />
                                <rect x="90" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="103" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="107" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="113" y="130" rx="3" ry="3" width="30" height="30" />
                                <rect x="180" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="195" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="200" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="205" y="130" rx="3" ry="3" width="30" height="30" />
                            </ContentLoader>}
                           
                        </div>
                        </div>
                        <div>
                            <div className="panel-item text-center" onClick={()=>this.props.addComponent(3,{})}>
                            <div className="panel-head">
                                Testimonials Section
                            </div>
                           {<ContentLoader animate={false} backgroundColor="#415889" foregroundColor="#415889">
                                <rect x="0" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="13" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="17" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="23" y="130" rx="3" ry="3" width="30" height="30" />
                                <rect x="90" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="103" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="107" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="113" y="130" rx="3" ry="3" width="30" height="30" />
                                <rect x="180" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="195" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="200" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="205" y="130" rx="3" ry="3" width="30" height="30" />
                            </ContentLoader>}
                           
                        </div>
                        </div>
                        <div>
                            <div className="panel-item text-center" onClick={()=>this.props.addComponent(4,{})}>
                            <div className="panel-head">
                                Text Section
                            </div>
                           {<ContentLoader animate={false} backgroundColor="#415889" foregroundColor="#415889">
                                <rect x="0" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="13" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="17" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="23" y="130" rx="3" ry="3" width="30" height="30" />
                                <rect x="90" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="103" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="107" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="113" y="130" rx="3" ry="3" width="30" height="30" />
                                <rect x="180" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="195" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="200" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="205" y="130" rx="3" ry="3" width="30" height="30" />
                            </ContentLoader>}
                           
                        </div>
                        </div>
                        <div>
                            <div className="panel-item text-center" onClick={()=>this.props.addComponent(5,{})}>
                            <div className="panel-head">
                                Footer Section
                            </div>
                           {<ContentLoader animate={false} backgroundColor="#415889" foregroundColor="#415889">
                                <rect x="0" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="13" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="17" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="23" y="130" rx="3" ry="3" width="30" height="30" />
                                <rect x="90" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="103" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="107" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="113" y="130" rx="3" ry="3" width="30" height="30" />
                                <rect x="180" y="10" rx="5" ry="5" width="80" height="50" />
                                <rect x="195" y="80" rx="4" ry="4" width="50" height="13" />
                                <rect x="200" y="100" rx="3" ry="3" width="40" height="10" />
                                <rect x="205" y="130" rx="3" ry="3" width="30" height="30" />
                            </ContentLoader>}
                           
                        </div>
                        </div>
                       
                     
                    </Slider>
                </div>
            </div>
        </> );
    }
}
 
const mapDispatchToProps = dispatch =>{
    return{
        addComponent : (parent,elements)=>dispatch({type:'ADD_COMPONENT',parent,elements})
    }
}
export default connect(null,mapDispatchToProps)(AddComponent);