import React, { Component } from 'react';

class TeamTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
      const {elements:el} = this.props;
      const teamTemplate = el.content.map((item,index)=>(
        <>
          <div className="col-xl-3 col-sm-6 mb-5">
            <div className="bg-white rounded shadow-sm py-5 px-4"><img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="" width="100" className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm" />
              <h5 className="mb-0" contentEditable={true}>{item.name}</h5><span className="small text-uppercase text-muted" contentEditable={true}>{item.title}</span>
              <ul className="social mb-0 list-inline mt-3">
                <li className="list-inline-item"><a href={item.facebook} className="social-link"><i className="fa fa-facebook-f"></i></a></li>
                <li className="list-inline-item"><a href={item.twitter} className="social-link"><i className="fa fa-twitter"></i></a></li>
                <li className="list-inline-item"><a href={item.youtube} className="social-link"><i className="fa fa-youtube"></i></a></li>
                <li className="list-inline-item"><a href={item.linkedin} className="social-link"><i className="fa fa-linkedin"></i></a></li>
              </ul>
            </div>
          </div>
        </>
      ))
        return ( <>
        
<div className="container py-5">
    <div className="row mb-4">
      <div className="col-lg-5">
        <h2 className="display-4 font-weight-light" contentEditable={true}>{el.title}</h2>
      </div>
    </div>

    <div className="row text-center">
      
          {teamTemplate}

    </div>
  </div>
        </> );
    }
}
 
export default TeamTwo;