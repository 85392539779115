
const initioalState = {
    themes:[
        {
            title:"theme 1",
            pages:[
                {title:"home",components:[
                    // {
                        // parent:0,
                        // style:1,
                        // elements:{
                            // title:"What is Lorem Ipsum?",
                            // content:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae, repellendus, earum odit quasi laboriosam qui repellat ipsa harum eligendi facilis rerum? Alias inventore possimus nihil tempora! Dignissimos eaque voluptate necessitatibus autem inventore explicabo. Vero, error ad voluptas corporis reiciendis molestias sequi quo est cum animi recusandae porro nobis suscipit velit!",
                            // background:null,
                        // }
                    // },
                   
                ]}
            ],
            selectedPage:0,
            color:null,
            font:null,
            lang:null
        }
    ],
    links:[
        {title:"Home",link:"/",childs:[]},
        {title:"About",link:"/about",childs:[]},
        {title:"Contact Us",link:"/contact",childs:[]}
    ],
    components:[
        {type:"header" ,components:[
            {title:"Head",component:"Headers/HeaderOne"},
            {title:"Head 2",component:"Headers/HeaderTwo"},
        ]},
        {type:"services" ,components:[
            {title:"Services",component:"Services/ServiceOne"},
            {title:"Services 2",component:"Services/ServiceTwo"},
        ]},
        {type:"team" ,components:[
            {title:"Services",component:"Team/TeamOne"},
            {title:"Services 2",component:"Team/TeamTwo"},
        ]},
        {type:"testomonoals" ,components:[
            {title:"Head",component:"Testimonials/TestiOne"},
            {title:"Head 2",component:"Testimonials/TestiTwo"},
        ]},
        {type:"text" ,components:[
            {title:"Text",component:"Text/TextOne"},
            {title:"Text 2",component:"Text/TextTwo"},
        ]},
        {type:"footer" ,components:[
            {title:"Gallery",component:"Footer/FooterOne"},
            {title:"Gallery",component:"Footer/FooterTwo"},
        ]},
        {type:"contact" ,components:[
            {title:"contact",component:"Contact/ContactOne"},
            {title:"contact 2",component:"Contact/ContactTwo"},
        ]},
        {type:"gallery" ,components:[
            {title:"Gallery"},
        ]},
    ],
    selectedTheme:null,
    selectedPanel:null,
    screenSize:1280
}
const BuilderReducer = (state = initioalState,action) => {
    switch (action.type) {
        case "SELECT_THEME":
            console.log(action)
            return {...state,selectedTheme:action.index};
    
        case "SELECT_PANEL":
            console.log(action)
            return {...state,selectedPanel:action.title};
    
        case "ADD_COMPONENT":
            console.log(action)
            let selectedtheme = state.selectedTheme;
            let themes = [...state.themes];
            let theme = themes[selectedtheme];
            theme.pages[theme.selectedPage].components.push({ parent:action.parent,
                style:0,
                elements:{
                    ...action.elements
                }})
                console.log(typeof themes)

            return {...state,themes:[...themes],selectedPanel:null};
        case "STYLE_CHANGE":
            let style = action.style;
            var newState = {...state};
            let currentTheme = newState.themes[newState.selectedTheme];
            let currentPage = currentTheme.pages[currentTheme.selectedPage];
            let currentComponent = currentPage.components[action.index];
            let styleComponents = newState.components[action.parent].components;
            console.log(styleComponents);
            if(action.dir === "next"){
                if((style+1) < styleComponents.length){
                    currentComponent.style = style + 1
                }
            }else if(action.dir === "prev"){
                if(style > 0){
                    currentComponent.style = style - 1;
                    console.log(currentComponent.style,style)

                }
            }
            
            return newState
        case "CHANGE_COMPONENT_ORDER":
            
            let index = action.index;
            var newState = {...state};
            let currentTheme2 = newState.themes[newState.selectedTheme];
            let currentPage2 = currentTheme2.pages[currentTheme2.selectedPage];
            let currentPageComponents = [...currentTheme2.pages[currentTheme2.selectedPage].components];
            let currentComponent2 = currentPage2.components[action.index];


            
            if(action.dir === "up"){

                currentPageComponents.splice(index-1, index, currentPage2.components.splice(index, 1)[0]);
                if(index > 0){
                    let temp = currentPageComponents[index-1];
                    let tempindex = currentPageComponents[index];
                    currentPageComponents[index-1] = tempindex;
                    currentPageComponents[index] = temp;
                    console.log(currentPageComponents);
                }
                    
                }else if(action.dir === "down"){
                    // currentPageComponents.splice(index+1, index, currentPageComponents.splice(index, 1)[0]);
                    console.log(currentPageComponents)
                // if(style > 0){
                //     currentComponent.style = style - 1;
                //     console.log(currentComponent.style,style)

                // }
            }
            return newState;

        case "REMOVE_COMPONENT": 
            var newState = {...state};
            let mytheme = newState.themes[newState.selectedTheme];
            let mycurrentPage = mytheme.pages[mytheme.selectedPage];
            mycurrentPage.components.splice(action.index,1);
            console.log('hi')
            return newState
        case "RESIZE":
            return {...state,screenSize:action.size};
        default:
            return state;
    }
} 

export default BuilderReducer;