import React, { Component } from 'react';

class HeaderTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const {elements:el} = this.props;
        return ( <>
           <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <div className="container">
    <a className="navbar-brand" href="#">Start Bootstrap</a>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
    <div className="collapse navbar-collapse" id="navbarResponsive">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item active">
          <a className="nav-link" href="#">Home
                <span className="sr-only">(current)</span>
              </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">About</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Services</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Contact</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<header>
      <div className="carousel-item active" style={{"background-image": "url('https://source.unsplash.com/RCAhiGJsUUE/1920x1080')"}}>
        <div className="carousel-caption d-none d-md-block" style={{top:"10%"}}>
          <h3 className="display-4" contentEditable={true}>{el.title}</h3>
          <p className="lead" contentEditable={true}>{el.content}</p>
        </div>
      </div>

</header>


        </> );
    }
}
 
export default HeaderTwo;
