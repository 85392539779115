import React, { Component } from 'react';

class ServiceTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
      const {elements:el} = this.props;
      const servicesTemplate = el.content.map((item,index)=>(
        <>
        <div class="row">
        <div class="col-md-7">
          <a href="#">
            <img class="img-fluid rounded mb-3 mb-md-0" src="http://placehold.it/700x300" alt=""/>
          </a>
        </div>
        <div class="col-md-5">
          <h3 contentEditable={true}>{item.title}</h3>
          <p contentEditable={true}>{item.content}</p>
          <a class="btn btn-primary" href="#">View Project</a>
        </div>
      </div>

      <hr/>
        </>
      )) 
        return ( <>
    <div class="container">
      <h1 class="my-4" contentEditable={true}>{el.title} </h1>
      {servicesTemplate}
    </div>
        </> );
    }
}
 
export default ServiceTwo;