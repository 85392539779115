import { connect } from 'react-redux';
import './assets/css/bootstrap.min.css';
import Themes from './Components/ProjectComponents/Themes/Themes';
import Builder from './Components/ProjectComponents/Builder';

function App(props) {
  console.log(props.selectedTheme);
  return (
    <div className="App">
      {props.selectedTheme === null ? <Themes />:<Builder />}
    </div>
  );
}


const mapStateToProps = (state) => {
  return{
    selectedTheme:state.builder.selectedTheme
  }
}
export default connect(mapStateToProps)(App);
