import React, { Component } from 'react';
import { connect } from 'react-redux';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const {themes,selectedTheme} = this.props;
        const theme = themes[selectedTheme];
        const pagesTemplate = theme.pages.map((item,index)=> (
            <li key={index}><a className="dropdown-item" href="#">Page #{index+1}</a></li>
        ));
        return (<>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
                    <a className="navbar-brand" href="#">Poga PB</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                           
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i className="fa fa-file-alt"></i>  Pages
                                 </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    {pagesTemplate}
                                    <li><a className="dropdown-item" href="#"><i className="fa fa-plus"></i> Add New </a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="#"> <i className="fa fa-fill-drip"></i> Colors</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="#"><i className="fa fa-font"></i> Fonts</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="#"><i className="fa fa-globe-europe"></i> Langs</a>
                            </li>
                        </ul>
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                           
                            <li className="nav-item">
                                <a className="nav-link text-warning" onClick={()=>this.props.openPanel('AddComponent')} aria-current="page" href="#"> <i className="fa fa-plus"></i> Add Component </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={()=>this.props.resize(360)} aria-current="page" href="#"><i className="fa fa-mobile-alt"></i></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={()=>this.props.resize(780)} aria-current="page" href="#"><i className="fa fa-tablet-alt"></i></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={()=>this.props.resize(1280)} aria-current="page" href="#"><i className="fa fa-desktop"></i></a>
                            </li>
                            <li className="nav-item">
                                <button className="btn-info btn btn-sm" style={{marginTop:5}} onClick={this.props.selectTheme} aria-current="page" href="#"> Back To Themes</button>
                            </li>
                          
                        </ul>

                    </div>
                </div>
            </nav>
        </>);
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        selectTheme:()=>dispatch({type:"SELECT_THEME",index:null}),
        openPanel:(title)=>dispatch({type:"SELECT_PANEL",title}),
        resize:(size)=>dispatch({type:"RESIZE",size}),

    }
}
const mapStateToProps = state =>{
    return{
        themes:state.builder.themes,
        selectedTheme:state.builder.selectedTheme,
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Navbar);