import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server'

class Editor extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            changes:0
         }
    }
    render() { 
        const { themes,selectedTheme ,components} = this.props;
        const theme = themes[selectedTheme];
        const page = theme.pages[theme.selectedPage];
        const componentsTemplate = page.components.map((item,index)=>{
            const component = components[item.parent].components[item.style];
            const Com = require('./Components/'+component.component).default
            const plainText = ReactDOMServer.renderToString(<Com key={index} elements={item.elements}/>)
            console.log(item.style)
            let padding = 0;
            if(item.parent !== 0 && item.parent !== 5){
                padding = 80
            }
            return(
                <div key={index} className="edit-content"  style={{clear:"both",paddingTop:padding,paddingBottom:padding}}>
                    <div className="tools">
                        <div className="row">
                            <div className="col-md-6">
                                <button className="option" onClick={()=>{
                                    this.props.styleChange(index,item.style,item.parent,"prev")
                                    this.setState({ changes:this.state.changes+1  })
                                }}> <i className="fa fa-long-arrow-alt-left"></i> Prev Style </button>
                                <button className="option" onClick={()=>{
                                    this.props.styleChange(index,item.style,item.parent,"next") 
                                    this.setState({ changes:this.state.changes+1  })
                                }}> Next Style  <i className="fa fa-long-arrow-alt-right"></i> </button>
                                <button className="option" onClick={()=>{
                                    this.props.removeComponent(index);
                                    this.setState({ changes:this.state.changes+1  })
                                }}> <i className="fa fa-trash"></i>  Remove </button>
                                <button className="option" onClick={()=>{
                                    this.props.orderChange(index,"up") 
                                    this.setState({ changes:this.state.changes+1  })
                                }}> <i className="fa fa-long-arrow-alt-up"></i> Up</button>
                                <button className="option"> <i className="fa fa-long-arrow-alt-down"></i> Down </button>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                    </div>
                    <Com elements={item.elements} index={index}/>
                </div>
            )
        });
        return ( <>
        <div className="container-fluid">
            <div className="editor" style={{width:this.props.screenSize,margin:"auto"}}>
                {page.components.length > 0 ?componentsTemplate:<div className="text-center add-component">
                    <i className="fa fa-grin-alt"></i>
                    <p>Add The First Component</p>
                    <p> <button className="btn btn-info circle" onClick={()=>this.props.openPanel('AddComponent')}> <i className="fa fa-plus"></i> Add New</button> </p>
                </div>}
            </div>
        </div>
        </> );
    }
}
 
const mapStateToProps = state =>{
    return{
        themes:state.builder.themes,
        components:state.builder.components,
        selectedTheme:state.builder.selectedTheme,
        screenSize:state.builder.screenSize
    }
}
const mapDispatchToProps = dispatch =>{
    return{
        removeComponent:(index)=>dispatch({type:"REMOVE_COMPONENT",index}),
        openPanel:(title)=>dispatch({type:"SELECT_PANEL",title}),
        styleChange:(index,style,parent,dir)=>dispatch({type:"STYLE_CHANGE",index,style,parent,dir}),
        orderChange:(index,dir)=>dispatch({type:"CHANGE_COMPONENT_ORDER",index,dir})
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Editor);