import React, { Component } from 'react';
import { connect } from 'react-redux';
import Editor from './Editor';
import Navbar from './Navbar';
import AddComponent from './Panels/AddComponent';
class Builder extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const {selectedPanel} = this.props;
        return ( <>
            <Navbar />
            {selectedPanel === 'AddComponent'?<AddComponent />:null}
            <Editor />
        </> );
    }
}
 
const mapStateToProps = state =>{
    return{
        themes:state.builder.themes,
        selectedTheme:state.builder.selectedTheme,
        selectedPanel:state.builder.selectedPanel,
    }
}
export default connect(mapStateToProps)(Builder);