import React, { Component } from 'react';
import { connect } from 'react-redux';

class Themes extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const themesTemplate = this.props.themes.map((item,index)=>(
            <div className="col-md-3"  key={index}>
                <div className="theme" onClick={()=>this.props.selectTheme(index)}>
                    <i className="fa fa-desktop"></i>
                    Theme #{index+1}
                    <i className="fa fa-trash remove"></i>
                </div>
            </div>
        ));
        return ( <>
            <div className="container">
                <div className="themes">
                    <div className="text-center">
                        <h3> Select Theme Or Add New  </h3>
                        <hr style={{color:"#ccc"}}/>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="theme add-new">
                                <i className="fa fa-plus"></i>
                                Add New
                            </div>
                           
                        </div>
                        {themesTemplate}
                    </div>
                </div>
            </div>
        </> );
    }
}
 
const mapStateToProps = (state)=>{
    return{
        themes:state.builder.themes
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectTheme: (index) => dispatch({type:"SELECT_THEME",index})
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Themes);