import React, { Component } from 'react';

class ServiceOne extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const {elements} = this.props;
        const servicesTemplate = elements.content.map((item,index)=>(
            <div class="col-lg-6 mb-4">
              <div class="card h-100">
                <a href="#"><img class="card-img-top" src="http://placehold.it/700x400" alt=""/></a>
                <div class="card-body">
                  <h4 class="card-title">
                    <a href="#" contentEditable={true}>{item.title}</a>
                  </h4>
                  <p class="card-text" contentEditable={true}>{item.content}</p>
                </div>
              </div>
            </div>
        ));
        return ( <>
            <div class="container">

            <h1 class="my-4" contentEditable={true}>{elements.title} </h1>
            <div class="row">
              
              {servicesTemplate}
            
            </div>


</div>
        </> );
    }
}
 
export default ServiceOne;