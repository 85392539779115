import React, { Component } from 'react';

class ContactOne extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( <>
            <form className="contact">
                <div className="row">
                    <div className="col-md-5">
                        <h2> Get In Touch</h2>
                        <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" className="form-control"/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Email</label>
                            <input type="text" className="form-control"/>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Subject</label>
                            <input type="text" className="form-control"/>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>message</label>
                            <textarea type="text" className="form-control"></textarea>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <input type="submit" className="btn btn-info"/>
                        </div>
                    </div>
                </div>
                    </div>
                    <div className="col-md-7">
                        <h3>Lorem ipsum dolor sit amet consectetur.</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore mollitia natus ut necessitatibus blanditiis quos fuga amet hic minima facilis?</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore mollitia natus ut necessitatibus blanditiis quos fuga amet hic minima facilis?</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore mollitia natus ut necessitatibus blanditiis quos fuga amet hic minima facilis?</p>
                    </div>
                </div>

            </form>
        </> );
    }
}
 
export default ContactOne;