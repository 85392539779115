import React, { Component } from 'react';

class TeamOne extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
		const {elements:el} = this.props;
		const teamTemplate = el.content.map((item,index)=>(
			<>
			<div className="col-xs-6 col-sm-4 col-md-3 i">
			<div className="c text-center">
				<div className="wrap">
					<img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="#" width="270" height="270" className="img-responsive"/>
					<div className="info">
						<h3 className="name" contentEditable={true}>{item.name}</h3>
						<h4 className="position" contentEditable={true}>{item.title}</h4>
					</div>
				</div>
				<div className="more">
					<p contentEditable={true}>{item.content}</p>
					<div className="socials">
						<a href={item.facebook} title="#" className="facebook"><i className="fa fa-facebook"></i></a>
						<a href={item.twitter} title="#" className="twitter"><i className="fa fa-twitter"></i></a>
						<a href={item.youtube} title="#" className="google-plus"><i className="fa fa-youtube"></i></a>
						<a href={item.linkedin} title="#" className="linkedin"><i className="fa fa-linkedin"></i></a>
					</div>
				</div>
			</div>
		</div>
			</>
		));
        return ( 
            <>
            <div className="container">
<div className="section ourTeam">
	<header className="text-center">
		<h2 contentEditable={true}>{el.title}</h2>
	</header>
	<div className="row">
			{teamTemplate}
	</div>
</div>
</div>
            </>
         );
    }
}
 
export default TeamOne;